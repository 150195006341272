import * as React from 'react';
import { QuotesContent, QuotesContentType } from '../types';
// import range from 'lodash-es/range';
// import { IconStar } from 'styleguide/icons';

const Quote = ({ ...props }: QuotesContentType) => (
  <div
    className="h-[31vh] w-full bg-cover bg-no-repeat lg:h-[calc(100vh-80px)] lg:bg-bottom"
    style={{
      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.77)), url(${props.backgroundImage})`,
    }}
  >
    {/* <div className="flex h-full flex-col justify-end pl-4 text-white lg:pr-4 -lg:justify-between"> */}
    {/*   <div className="mb-6 font-hvMedium text-base leading-5 md:text-2xl lg:text-4xl -lg:mt-10 -lg:w-2/3 -lg:self-end"> */}
    {/*     {props.quote} */}
    {/*   </div> */}
    {/*   <div> */}
    {/*     <div className="mb-2 flex"> */}
    {/*       {range(0, props.stars).map(n => ( */}
    {/*         <IconStar key={n} size="sm" color="white" className="pr-1" /> */}
    {/*       ))} */}
    {/*     </div> */}
    {/*     <div className="mb-2 font-hvMedium text-base leading-5 lg:text-4xl">{props.name}</div> */}
    {/*     <div className="mb-1 font-hvMedium text-sm leading-5 lg:text-base">{props.occupation}</div> */}
    {/*     <div className="mb-4 font-hvThin text-sm leading-5 lg:text-base">{props.companyDesc}</div> */}
    {/*   </div> */}
    {/* </div> */}
  </div>
);

const Quotes = () => {
  const [randomQuote, setRandomQuote] = React.useState(null);

  React.useEffect(() => {
    const quote = QuotesContent[Math.floor(Math.random() * QuotesContent.length)];
    setRandomQuote(quote);
  }, []);

  return (
    <Quote
      backgroundImage={randomQuote?.backgroundImage}
      quote={randomQuote?.quote}
      stars={randomQuote?.stars}
      name={randomQuote?.name}
      occupation={randomQuote?.occupation}
      companyDesc={randomQuote?.companyDesc}
    />
  );
};

export default Quotes;
