import backgroundImg from 'assets/images/stories/make-something-swift.jpg';
import backgroundImg2 from 'assets/images/man-review.png';

export interface Error {
  title: string;
  messages: string[];
}

export interface QuotesContentType {
  backgroundImage?: string;
  quote: string;
  stars: number;
  name: string;
  occupation?: string;
  companyDesc?: string;
}

export const QuotesContent: QuotesContentType[] = [
  {
    backgroundImage: backgroundImg2,
    quote:
      '“Nobody cares more about the success of their clients, the quality of the products, and meeting the needs of the clients.”',
    stars: 5,
    name: `Jonathan O'neal`,
    occupation: 'Director of Marketing & Communication, Doinonia Christian Center',
  },
  {
    backgroundImage: backgroundImg2,
    quote:
      '“The only print company I use for all my advertising needs! Professional, Quality and timely on all there business!”',
    stars: 5,
    name: 'JEFFREY KINDER',
    occupation: 'Professional Rodeo Announcer, Cowboy Country Outfitters',
  },
  {
    backgroundImage: backgroundImg2,
    quote: `“Printivity's focus on core values and targeted product offerings make you a top contender for many projects.”`,
    stars: 5,
    name: 'James Cognitore',
    occupation: 'Advance Print LV',
  },
  {
    backgroundImage: backgroundImg2,
    quote:
      '“Everything was absolutely exceptional. The products came in excellent condition and the processing/shipping time was phenomenal!”',
    stars: 5,
    name: 'Martin Stever',
    occupation: 'Assistant Investor Relations & Marketing Manager, Pacific West Land, LLC',
  },
  {
    backgroundImage: backgroundImg,
    quote:
      '“At every step of the process, helpful and friendly people at Printivity assisted with the project.  I will certainly use Printivity again for all my print jobs, big and small.”',
    stars: 5,
    name: 'Debra  Baldwin',
    occupation: 'Chari and Associate Professor, University of Dallas',
  },
  {
    backgroundImage: backgroundImg2,
    quote: `“Printivity is my top choice. I think you'd be hard-pressed to find anything better from a quality or price standpoint!”`,
    stars: 5,
    name: 'Adam Pavis',
    occupation: 'Senior Brand Designer, Virtuous Software',
  },
  {
    backgroundImage: backgroundImg2,
    quote:
      '“My standards are very high when it comes to print, quality, and customer service, and Printivity exceeds my expectations every time without fail.”',
    stars: 5,
    name: 'Adam Pavis',
    occupation: 'Senior Brand Designer, Virtuous Software',
  },
  {
    backgroundImage: backgroundImg,
    quote: '“The ease of ordering combined with the quality of the prints has made me a repeat customer.”',
    stars: 5,
    name: 'Karley Clawson',
  },
  {
    backgroundImage: backgroundImg,
    quote: `“Loved the communication with the company, they email you and confirm that you love the digital proof, and it's a real person on the other side!”`,
    stars: 5,
    name: 'ilearys fernandez',
  },
  {
    backgroundImage: backgroundImg,
    quote: `“My prints came out fabulous, and the packaging showed a tremendous amount of care for my order.”`,
    stars: 5,
    name: 'Kerri Kirk',
  },
  {
    backgroundImage: backgroundImg2,
    quote: `“Printivity gives the very best in service and customer care. I am very confident with every task they have performed for me.”`,
    stars: 5,
    name: 'Samuel Reynolds',
  },
  {
    backgroundImage: backgroundImg2,
    quote: `“
    I loved how helpful customer service was when I called. My project looks amazing and will definitely be using them again! A business you can count on!”`,
    stars: 5,
    name: 'Marci  Seither',
  },
];
