import * as React from 'react';
import cn from 'classnames';

import Quotes from '../Quotes/Quotes';

const FormWrapper = ({ children, ...props }) => (
  <div className="grid lg:grid-cols-2">
    <div
      className={cn(
        'mx-auto flex h-full min-h-[calc(100vh-38vh)] w-[360px] flex-col justify-center lg:min-h-[calc(100vh-80px)] -lg:order-last -sm:w-[320px]',
        props.signUp && 'min-h-[calc(100vh-64px)]',
      )}
    >
      {children}
    </div>
    <div className={props.signUp && '-lg:hidden'}>
      <Quotes />
    </div>
  </div>
);

export default FormWrapper;
